/* DO NOT MODIFY, THIS FILE IS GENERATED BY THE BSIT CLI.
THE BSIT CLI IS COPYRIGHT MATERIAL OF SUBLEEME SA (bsit.com).
A COMMERCIAL LICENSE IS REQUIRED IN ORDER TO RUN THIS TOOLING.
CONTACT support@bsit.com IN CASE OF INQUIRY. */
var ___module_mybsit_core = angular.module('mybsit-core', ['ui.router']);;
				___module_mybsit_core.name = "mybsit-core";
function functionName(fun) {
  var ret = fun.toString();
  ret = ret.substr('function '.length);
  ret = ret.substr(0, ret.indexOf('('));
  return ret;
}
function camelToSnake(str) {
  return str.replace(/\W+/g, '-').replace(/([a-z\d])([A-Z])/g, '$1-$2').toLowerCase();
}
function snakeToCamel(str) {
  return str.replace(/\W+(.)/g, function(x, chr) {
    return chr.toUpperCase();
  });
}
function $__injector() {
  return angular.element(document.body).injector() || angular.injector();
}
function $__instantiate(clazz, locals) {
  return $__injector().instantiate(clazz, locals);
}
function $__invoke(fn, obj) {
  return $__injector().invoke(fn, obj);
}
var Service = function() {
  function Service() {}
  return ($traceurRuntime.createClass)(Service, {
    invoke: function(fn) {
      if (fn)
        return $__invoke(fn, this);
    },
    resource: function(resource) {
      Object.assign(this, resource);
      this.save = function(obj) {
        return (new resource(obj)).$save();
      };
    }
  }, {register: function(name, service, options) {
      if (!options)
        options = {};
      if (typeof(name) === "function") {
        service = name;
        name = functionName(name);
      }
      if (View.verbose)
        console.log('Registering service', name);
      angular.module(options.module || window.$APP_CONFIG.name || 'app').service(name, function() {
        return $__instantiate(service);
      });
    }});
}();
;
var Component = function() {
  function Component() {
    "ngInject";
  }
  return ($traceurRuntime.createClass)(Component, {
    invoke: function(fn) {
      if (fn)
        return $__invoke(fn, this);
    },
    go: function(state, params) {
      try {
        $__injector().get('tpxState').go(state, params);
      } catch (e) {
        $__injector().get('$state').go(state, params);
      }
    },
    trigger: function(fn) {
      if (fn)
        $__invoke(fn, this);
    },
    broadcast: function(event) {
      if (this.scope)
        this.scope.$broadcast(event);
    },
    emit: function(event) {
      if (this.scope)
        this.scope.$emit(event);
    },
    on: function(event, handler) {
      if (this.scope)
        this.scope.$on(event, handler);
    }
  }, {});
}();
var Directive = function($__super) {
  function Directive() {
    "ngInject";
    $traceurRuntime.superConstructor(Directive).call(this);
  }
  return ($traceurRuntime.createClass)(Directive, {}, {register: function(name, clazz, options) {
      if (!options)
        options = {};
      if (typeof(name) === "function") {
        options = clazz;
        clazz = name;
        name = functionName(clazz);
      }
      var directiveName = name.substr(0, 1).toLowerCase() + name.substr(1);
      var snake = camelToSnake(name);
      if (View.verbose)
        console.log('Registering directive', name);
      options = options || {};
      angular.module(options.module || window.$APP_CONFIG.name || 'app').directive(directiveName, function() {
        var controller = ['$scope', function($scope) {
          var $__3 = this;
          var instance = $__instantiate(clazz, {$scope: $scope});
          Object.assign(this, instance);
          Object.assign(this, $scope);
          this.__proto__ = clazz.prototype;
          if (navigator.appVersion.indexOf("MSIE 10") !== -1 && clazz.IE10 && Array.isArray(clazz.IE10)) {
            clazz.IE10.forEach(function(k) {
              return $__3[k] = instance[k];
            });
            this.invoke = instance.invoke;
          }
          $scope.view = this;
        }];
        if (options.restrict === 'E')
          options.templateUrl = snake + '.html';
        options.controllerAs = 'view';
        options.bindToController = true;
        options.controller = controller;
        options.link = function() {
          try {
            clazz.prototype.link.apply(clazz.prototype, arguments);
          } catch (e) {
            console.log('ERROR in directive', name, e);
          }
        };
        if (clazz.prototype.compile)
          options.compile = function() {
            try {
              clazz.prototype.compile.apply(clazz.prototype, arguments);
            } catch (e) {
              console.log('ERROR in directive', name, e);
            }
          };
        return options;
      });
    }}, $__super);
}(Component);
var Dialog = function($__super) {
  function Dialog() {
    "ngInject";
    $traceurRuntime.superConstructor(Dialog).call(this);
  }
  return ($traceurRuntime.createClass)(Dialog, {
    show: function(r) {
      var $__3 = this;
      if (r)
        this.remove = true;
      if (!this.modal) {
        var injector = angular.element(document.body).injector();
        var modal = injector.get('$ionicModal');
        var scope = injector.get('$rootScope').$new(true);
        this.scope = scope;
        var template = camelToSnake(Object.getPrototypeOf(this).name) + '.html';
        scope.view = this;
        modal.fromTemplateUrl(template, {
          scope: scope,
          animation: 'slide-in-up'
        }).then(function(modal) {
          $__3.modal = modal;
          scope.$emit('modal.loaded');
          injector.get('$rootScope').$on('$stateChangeSuccess', function() {
            injector.get('$timeout')(function() {
              $__3.hide();
            }, 600);
          });
        });
        scope.$on('modal.shown', function() {
          return $__3.trigger($__3.onShow);
        });
        scope.$on('modal.hidden', function() {
          return $__3.trigger($__3.onHide);
        });
        this.scope.$on('modal.loaded', function() {
          return $__3.modal.show();
        });
      } else {
        this.modal.show();
      }
      this.promise = new Promise(function(resolve, reject) {
        $__3.resolve = resolve;
        $__3.reject = reject;
      });
      return this.promise;
    },
    confirm: function(response) {
      this.resolve(response);
    },
    cancel: function() {
      this.reject();
    },
    hide: function() {
      if (this.modal)
        this.modal.hide();
      if (this.modal && this.remove) {
        this.scope.$destroy();
        this.modal.remove();
        this.modal = null;
      }
    }
  }, {register: function(name, clazz, options) {
      if (!options)
        options = {};
      if (typeof(name) === "function") {
        clazz = name;
        name = functionName(clazz);
      }
      if (View.verbose)
        console.log('Registering dialog', name);
      clazz.prototype.name = name;
      angular.module(options.module || window.$APP_CONFIG.name || 'app').service(name, clazz);
    }}, $__super);
}(Component);
;
var _listeners = {};
var __togetair_views = {};
var View = function($__super) {
  View.$inject = ["$scope"];
  function View($scope) {
    "ngInject";
    $traceurRuntime.superConstructor(View).call(this);
  }
  return ($traceurRuntime.createClass)(View, {}, {
    get: function(name) {
      return __togetair_views[name];
    },
    register: function(name, clazz, options) {
      if (!options)
        options = {};
      if (typeof(name) === "function") {
        options = clazz;
        clazz = name;
        name = functionName(clazz);
      }
      var snake = camelToSnake(name);
      if (View.verbose)
        console.log('Registering view', name);
      var cName = name + 'Controller';
      options = options || {};
      angular.module(options.module || window.$APP_CONFIG.name || 'app').controller(cName, ['$scope', '$rootScope', function($scope, $rootScope) {
        var $__3 = this;
        this.scope = $scope;
        var instance = $__instantiate(clazz, {$scope: $scope});
        Object.assign(this, instance);
        this.__proto__ = instance.__proto__;
        if (navigator.appVersion.indexOf("MSIE 10") !== -1 && clazz.IE10 && Array.isArray(clazz.IE10)) {
          clazz.IE10.forEach(function(k) {
            return $__3[k] = instance[k];
          });
          this.invoke = instance.invoke;
        }
        $scope.view = this;
        if (!View.views)
          View.views = {};
        __togetair_views[name] = this;
        if (window.ionic) {
          $scope.$on('$ionicView.afterEnter', function(e, data) {
            var backBtnEle = document.querySelector('.back-button');
            if (backBtnEle && data) {
              var hasBack = data.enableBack && data.showBack;
              backBtnEle.classList[hasBack ? 'remove' : 'add']('hide');
            }
          });
          $scope.$on('$ionicView.beforeEnter', function() {
            return $__3.trigger($__3.beforeEnter);
          });
          $scope.$on('$ionicView.beforeLeave', function() {
            return $__3.trigger($__3.beforeLeave);
          });
          $scope.$on('$ionicView.afterEnter', function() {
            return $__3.trigger($__3.afterEnter);
          });
          $scope.$on('$ionicView.afterLeave', function() {
            return $__3.trigger($__3.afterLeave);
          });
        } else {
          var self = this;
          $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
            if (fromState.name === snake)
              self.trigger(self.beforeLeave);
            if (toState.name === snake)
              self.trigger(self.beforeEnter);
          });
          $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
            if (fromState.name === snake)
              self.trigger(self.afterLeave);
          });
          $rootScope.$on('$viewContentLoading', function(event, viewConfig) {});
          $scope.$on('$viewContentLoaded', function(event) {
            if (self.trigger)
              self.trigger(self.afterEnter);
          });
        }
        if (typeof this.watches === 'function') {
          this.watches(function(watchKey, watchFn) {
            if (View.verbose)
              console.log('Watching', watchKey);
            $scope.$watch(watchKey, function(a, b, c, d) {
              if (View.verbose)
                console.log(a);
              watchFn.apply($__3, [a, b, c, d]);
            }, true);
          });
        }
      }]);
      if (!options || !options.noroute) {
        ___module_mybsit_core.config(["$stateProvider", function($stateProvider) {
          $stateProvider.state(snake, {
            url: options.route || '/' + snake + (options.query ? '?' + options.query : ''),
            templateUrl: options.templateUrl || snake + '.html',
            controller: cName,
            controllerAs: 'view',
            params: options.params || {},
            bindToController: true,
            resolve: options.resolve
          });
        }]);
      }
      if (_listeners[name]) {
        var $__7 = true;
        var $__8 = false;
        var $__9 = undefined;
        try {
          for (var $__5 = void 0,
              $__4 = (_listeners[name])[Symbol.iterator](); !($__7 = ($__5 = $__4.next()).done); $__7 = true) {
            var i = $__5.value;
            i(clazz);
          }
        } catch ($__10) {
          $__8 = true;
          $__9 = $__10;
        } finally {
          try {
            if (!$__7 && $__4.return != null) {
              $__4.return();
            }
          } finally {
            if ($__8) {
              throw $__9;
            }
          }
        }
      } else {
        _listeners[name] = [];
        _listeners[name].registered = true;
      }
    },
    defaultRoute: function(route) {
      ___module_mybsit_core.config(['$urlRouterProvider', function(urp) {
        urp.otherwise(route);
      }]);
    },
    onRegister: function(clazz, fn) {
      if (!_listeners[clazz])
        _listeners[clazz] = [];
      _listeners[clazz].push(fn);
      if (_listeners[clazz].registered) {
        var $__7 = true;
        var $__8 = false;
        var $__9 = undefined;
        try {
          for (var $__5 = void 0,
              $__4 = (_listeners[clazz])[Symbol.iterator](); !($__7 = ($__5 = $__4.next()).done); $__7 = true) {
            var i = $__5.value;
            i(clazz);
          }
        } catch ($__10) {
          $__8 = true;
          $__9 = $__10;
        } finally {
          try {
            if (!$__7 && $__4.return != null) {
              $__4.return();
            }
          } finally {
            if ($__8) {
              throw $__9;
            }
          }
        }
      }
      return function() {
        _listeners[clazz].remove(fn);
      };
    }
  }, $__super);
}(Component);
var Watcher = function() {
  Watcher.$inject = ["$rootScope"];
  function Watcher($rootScope) {
    "ngInject";
    
    this.scope = $rootScope.$new(true);
  }
  return ($traceurRuntime.createClass)(Watcher, {watch: function(fnWatch, fnDo, deep) {
      if (Array.isArray(fnWatch)) {
        this.scope.arrays = this.scope.arrays || [];
        this.scope.arrays.push(array);
        var index = this.scope.arrays.length - 1;
        var w = this.scope.$watchCollection('arrays[' + index + ']', fnDo);
        return function() {
          w();
          this.scope.arrays.slide(index, 1);
        };
      } else {
        return this.scope.$watch(fnWatch, fnDo, deep);
      }
    }}, {});
}();
___module_mybsit_core.service('Watcher', Watcher);

;
___module_mybsit_core.factory('$cloudinary', ["$q", "$http", "$config", function($q, $http, $config) {
  return function(key, file, config) {
    return $q(function(resolve, reject) {
      if (!$config.cloudinarySignUrl)
        throw Error('No cloudinary sign url configured in $config service');
      $http.get($config.cloudinarySignUrl + '?key=' + key + '&format=png').success(function(signature) {
        var xhr = new XMLHttpRequest();
        xhr.file = file;
        if (xhr.upload) {
          xhr.upload.onprogress = function(e) {
            var done = e.loaded || e.position,
                total = e.total || e.totalSize;
            var percent = Math.floor(done / total * 1000) / 10;
            if (config && config.onProgress)
              config.onProgress(percent);
          };
        }
        xhr.onreadystatechange = function(e) {
          if (4 === this.readyState) {
            if (this.status == 200) {
              resolve(JSON.parse(this.response));
            } else {
              reject();
            }
          }
        };
        xhr.open('POST', signature.postUrl, true);
        var formData = new FormData();
        formData.append('file', file);
        if (signature && signature.headers) {
          for (var k in signature.headers) {
            formData.append(k, signature.headers[k]);
          }
        }
        xhr.send(formData);
      }).error(reject);
    });
  };
}]);

;
___module_mybsit_core.directive('mobileFilePicker', ["$window", function($window) {
  return {
    restrict: 'E',
    templateUrl: 'mobile-file-picker.html',
    scope: {
      onComplete: '&',
      inputId: '@inputId'
    },
    link: function($scope, $element, $attrs) {
      var el = $window.document.getElementById('mobilefilepickerinput');
      el.onchange = function(event) {
        $scope.onComplete({
          file: event.target.files[0],
          inputId: $attrs.inputId
        });
      };
    }
  };
}]);

;
___module_mybsit_core.factory('WebFilePicker', ["$imageResizer", "$q", function($imageResizer, $q) {
  return function() {
    return $q(function(resolve, reject) {
      console.log('inside web file picker');
      var input = document.createElement('input');
      input.setAttribute("data-tap-disabled", true);
      console.log('inside wfp input :', input);
      input.type = 'file';
      input.onclick = function(e) {
        console.log('input clicked !');
        console.log('event : ', e);
      };
      input.onchange = function(event) {
        console.log('on change :');
        var file = event.target.files[0];
        var obj = {file: file};
        console.log('inside wfp file :', file);
        console.log('inside wfp obj :', obj);
        resolve(obj);
      };
      input.click();
    });
  };
}]);

;
___module_mybsit_core.factory('$globalization', function() {
  var getPromise = function(fn) {
    if (navigator.globalization) {
      return new Promise(fn);
    } else {
      return Promise.reject();
    }
  };
  var service = {};
  service.getPreferredLanguage = function() {
    return getPromise(function(resolve, reject) {
      navigator.globalization.getPreferredLanguage(resolve, reject);
    });
  };
  service.getLocaleName = function() {
    return getPromise(function(resolve, reject) {
      navigator.globalization.getLocaleName(resolve, reject);
    });
  };
  service.getFirstDayOfWeek = function() {
    return getPromise(function(resolve, reject) {
      navigator.globalization.getFirstDayOfWeek(resolve, reject);
    });
  };
  service.dateToString = function(date, options) {
    return getPromise(function(resolve, reject) {
      navigator.globalization.dateToString(date, resolve, reject, options);
    });
  };
  service.stringToDate = function(dateString, options) {
    return getPromise(function(resolve, reject) {
      navigator.globalization.stringToDate(dateString, resolve, reject, options);
    });
  };
  service.getDatePattern = function(options) {
    return getPromise(function(resolve, reject) {
      navigator.globalization.getDatePattern(resolve, reject, options);
    });
  };
  service.getDateNames = function(options) {
    return getPromise(function(resolve, reject) {
      navigator.globalization.getDateNames(resolve, reject, options);
    });
  };
  service.isDayLightSavingsTime = function(date) {
    return getPromise(function(resolve, reject) {
      navigator.globalization.isDayLightSavingsTime(date, resolve, reject);
    });
  };
  service.numberToString = function(number, options) {
    return getPromise(function(resolve, reject) {
      navigator.globalization.numberToString(number, resolve, reject, options);
    });
  };
  service.stringToNumber = function(numberString, options) {
    return getPromise(function(resolve, reject) {
      navigator.globalization.stringToNumber(numberString, resolve, reject, options);
    });
  };
  service.getNumberPattern = function(options) {
    return getPromise(function(resolve, reject) {
      navigator.globalization.getNumberPattern(resolve, reject, options);
    });
  }, service.getCurrencyPattern = function(currencyCode) {
    return getPromise(function(resolve, reject) {
      navigator.globalization.getCurrencyPattern(currencyCode, resolve, reject);
    });
  };
  return service;
});

;
___module_mybsit_core.directive('imagePickerMaterial', ["b64toBlob", "$cloudinary", "uuid", function(b64toBlob, $cloudinary, uuid) {
  return {
    require: 'ngModel',
    restrict: 'E',
    templateUrl: 'image-picker-material.html',
    scope: {
      image: '=ngModel',
      user: '='
    },
    link: function($scope, $element, $attrs) {
      var video = document.getElementById('video-capture');
      var canvas = document.getElementById('canvas-capture');
      var imageE = document.getElementById('img-capture');
      var ctx = canvas.getContext('2d');
      var localMediaStream = null;
      function captureImage() {
        if (localMediaStream) {
          ctx.drawImage(video, 0, 0, 640, 480);
          document.getElementById('img-capture').src = canvas.toDataURL('image/jpeg');
          var options = {onProgress: function(i) {
              if ($scope.onProgress)
                $scope.onProgress({value: i});
              $scope.$apply();
            }};
          if (localMediaStream.stop) {
            localMediaStream.stop();
          } else {
            var tracks = localMediaStream.getTracks();
            for (var i = 0; i < tracks.length; i++)
              tracks[i].stop();
          }
          $cloudinary($scope.key || uuid(), b64toBlob(imageE.src.replace('data:image/jpeg;base64,', '')), options).then(function(result) {
            $scope.image = result;
          });
          $scope.pictureTaken = true;
        }
      }
      $scope.enableCamera = function() {
        if (!$scope.cameraEnabled) {
          video.addEventListener('click', captureImage, false);
          navigator.getUserMedia = (navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia);
          navigator.getUserMedia({video: true}, function(stream) {
            $scope.$apply(function() {
              $scope.cameraEnabled = true;
            });
            video.src = window.URL.createObjectURL(stream);
            localMediaStream = stream;
          }, function() {
            $scope.cameraEnabled = false;
          });
        } else {
          if ($scope.pictureTaken) {
            $scope.pictureTaken = false;
          } else {
            captureImage();
            $scope.pictureTaken = true;
          }
        }
      };
    }
  };
}]);

;
___module_mybsit_core.directive('imagePicker', ["$s3Upload", "uuid", "WebImagePicker", function($s3Upload, uuid, WebImagePicker) {
  return {
    restrict: 'A',
    scope: {
      key: '=',
      noImage: '@',
      imageOnUpload: '@',
      size: '=?',
      quality: '=?',
      disabled: '=?',
      onComplete: '&?',
      onProgress: '&?'
    },
    link: function($scope, $element, $attrs) {
      $element.attr('src', $scope.noImage);
      $scope.size = $scope.size || 960;
      $scope.quality = $scope.quality || 70;
      $element[0].onerror = function() {
        if ($element.attr('src') !== $scope.noImage)
          $element.attr('src', $scope.noImage);
      };
      $element.bind('click', function(event) {
        if (!$scope.disabled) {
          WebImagePicker($scope.size, $scope.quality).then(function(res) {
            if ($attrs.imageOnUpload)
              $element.attr('src', $attrs.imageOnUpload);
            angular.element($element).addClass('uploading');
            var blob = (res && res.blob) || res;
            return $s3Upload(uuid(), blob, {requestType: 'PUT'});
          }).then(function(res) {
            var $__3,
                $__4;
            if (!res || !res.file || !res.file.size)
              return;
            var $__2 = res || {},
                url = ($__3 = $__2.responseURL) === void 0 ? null : $__3,
                size = ($__4 = $__2.size) === void 0 ? null : $__4;
            url = (url && url.split('?')[0]) || null;
            console.log('INFO: image-picker.js#imagePicker - Picture is now accessible by following this link: ', url);
            $element.removeClass('uploading');
            $element.attr('src', url);
            if ($scope.onComplete)
              $scope.onComplete({value: {
                  url: url,
                  size: size
                }});
          }).catch(function(err) {
            $element.removeClass('uploading');
            console.log('ERROR: image-picker.js#imagePicker - Error while compressing image: ', err);
          });
        }
        event.preventDefault();
        event.stopPropagation();
      });
      $scope.$watch('src', function(src) {
        if (src)
          $element.attr('src', src);
      });
    }
  };
}]);

;
___module_mybsit_core.factory('WebImagePicker', ["$imageResizer", "$q", function($imageResizer, $q) {
  return function(size, quality) {
    return $q(function(resolve, reject) {
      var input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      angular.element(input).bind('change', function(event) {
        var $__2,
            $__4,
            $__5,
            $__6;
        var $__1 = (event && event.target) || {},
            files = ($__2 = $__1.files) === void 0 ? null : $__2;
        var $__3 = files || [],
            file = ($__4 = $__3[Symbol.iterator](), ($__6 = ($__5 = $__4.next()).done ? void 0 : $__5.value) === void 0 ? null : $__6);
        if (!file)
          return reject('NO_FILE_FOUND');
        $imageResizer(file, size, quality).then(function(blob) {
          return resolve(blob);
        }).catch(function(err) {
          return reject(err);
        });
      });
      input.click();
    });
  };
}]);

;
___module_mybsit_core.factory('b64toBlob', function() {
  return function(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
    var byteCharacters = atob(b64Data);
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, {type: contentType});
    return blob;
  };
});

;
___module_mybsit_core.factory('dataURItoBlob', function() {
  return function(dataURI) {
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);
    return new Blob([ia], {type: mimeString});
  };
});

;
___module_mybsit_core.factory('imageLoader', function() {
  return function($scope, $element, url, loadingImg, errorImg) {
    var loadingDefault = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNgYAAAAAMAASsJTYQAAAAASUVORK5CYII=";
    $element[0].src = loadingImg ? loadingImg : loadingDefault;
    $element.addClass('image-loading');
    $element.src = $scope.ngSrc;
    $element[0].onerror = function() {
      $element.attr('src', errorImg);
    };
    $element.bind('load', function(e) {
      if (/^(f|ht)tps?:\/\//i.test($element.attr('src'))) {
        $element.removeClass('image-loading');
        $element.css('-webkit-animation', 'fadeIn ease-in 0.3s');
      }
      $element.attr('src', url);
    });
  };
});

;
___module_mybsit_core.factory('$imageResizer', ["$q", "b64toBlob", "dataURItoBlob", function($q, b64toBlob, dataURItoBlob) {
  return function(fileOrURI, maxSize, quality) {
    if (typeof fileOrURI === 'string') {
      return $q(function(resolve) {
        var image = new Image();
        image.addEventListener('load', function() {
          var canvas = document.createElement('canvas');
          if (image.width > image.height) {
            canvas.width = Math.min(maxSize, image.width);
            canvas.height = image.height * (canvas.width / image.width);
          } else {
            canvas.height = Math.min(maxSize, image.height);
            canvas.width = image.width * (canvas.height / image.height);
          }
          var context = canvas.getContext('2d');
          context.imageSmoothingEnabled = true;
          context.webkitImageSmoothingEnabled = true;
          context.mozImageSmoothingEnabled = true;
          context.drawImage(image, 0, 0, canvas.width, canvas.height);
        });
        var blob = b64toBlob(fileOrURI);
        image.src = fileOrURI;
        resolve(blob);
      });
    } else {
      return $q(function(resolve) {
        var reader = new FileReader();
        reader.onload = function(e) {
          var image = new Image();
          image.addEventListener('load', function() {
            var canvas = document.createElement('canvas');
            if (image.width > image.height) {
              canvas.width = Math.min(maxSize, image.width);
              canvas.height = image.height * (canvas.width / image.width);
            } else {
              canvas.height = Math.min(maxSize, image.height);
              canvas.width = image.width * (canvas.height / image.height);
            }
            var context = canvas.getContext('2d');
            context.imageSmoothingEnabled = true;
            context.webkitImageSmoothingEnabled = true;
            context.mozImageSmoothingEnabled = true;
            context.drawImage(image, 0, 0, canvas.width, canvas.height);
            var qualityPercent = quality / 100;
            var blob = dataURItoBlob(canvas.toDataURL('image/jpeg', qualityPercent));
            resolve(blob);
          });
          image.src = e.target.result;
        };
        reader.readAsDataURL(fileOrURI);
      });
    }
  };
}]);

;
___module_mybsit_core.directive('imageSize', ["$parse", function($parse) {
  return {
    restrict: 'A',
    scope: false,
    link: function($scope, $element, $attrs) {
      var size = {};
      var setter = $parse($attrs.imageSize).assign;
      $attrs.$observe('imageSizeSrc', function(url) {
        if (url) {
          var myImage = new Image();
          myImage.name = url;
          myImage.onload = function() {
            size.height = this.height;
            size.width = this.width;
            setter($scope, size);
            return true;
          };
          myImage.src = url;
        }
      });
    }
  };
}]);

;
___module_mybsit_core.directive('imageViewPopup', ["$document", "$injector", function($document, $injector) {
  var div,
      doUnregisterBackAction;
  return function($scope, $element, $attrs) {
    var doHide = function(e) {
      div.addClass('image-glasspane-hide');
      setTimeout(function() {
        div.css('background-image', '');
      }, 500);
      if (e)
        e.preventDefault();
      if (e)
        e.stopPropagation();
      if (doUnregisterBackAction)
        doUnregisterBackAction();
      $document.find('body').off('click', doHide);
    };
    $element.on('click', function(e) {
      if (!div) {
        div = angular.element('<div>');
        div.addClass('image-glasspane');
        $document.find('body').eq(0).append(div);
      }
      $document.find('body').on('click', doHide);
      div.removeClass('image-glasspane-hide');
      div.css('background-image', 'url(' + $element[0].src + ')');
      try {
        var ip = $injector.get('$ionicPlatform');
        if (ip)
          doUnregisterBackAction = ip.registerBackButtonAction(doHide, 1000);
      } catch (e) {}
      e.preventDefault();
      e.stopPropagation();
    });
  };
}]);

;
___module_mybsit_core.directive('imageRef', function() {
  return function(scope, element, attrs) {
    element.addClass(attrs.imageRef);
  };
});
___module_mybsit_core.filter('imageRef', function() {
  function getStyle(className) {
    var styleSheets = window.document.styleSheets;
    for (var i = 0,
        sL = styleSheets.length; i < sL; i++) {
      var imagesCss = styleSheets[i] && styleSheets[i].href && styleSheets[i].href.split('/')[styleSheets[i].href.split('/').length - 1] === 'images.css';
      if (imagesCss) {
        var classes = void 0;
        try {
          classes = styleSheets[i].rules || styleSheets[i].cssRules;
        } catch (err) {
          console.log('Cannot access remote css rules', styleSheets[i]);
        }
        if (classes)
          for (var x = 0,
              L = classes.length; x < L; x++) {
            if (classes[x].selectorText === className) {
              var ret = classes[x].cssText || classes[x].style.cssText;
              if (ret.indexOf(classes[x].selectorText) == -1) {
                ret = classes[x].selectorText + "{" + ret + "}";
              }
              return ret;
            }
          }
      }
    }
  }
  var cache = {};
  return function(ref) {
    if (ref) {
      if (!cache[ref]) {
        var rule = getStyle('.' + ref);
        if (rule) {
          cache[ref] = /url\([\"\']?([^\)\"\'"]*)[\"\']?\)/g.exec(rule)[1];
        } else {
          throw new Error('Could not find CSS rule in which a b64 image is expected to be defined: ' + ref);
        }
      }
      return cache[ref];
    } else {
      throw new Error('A CSS class reference is expected for the imageRef filter');
    }
  };
});

;
___module_mybsit_core.factory('$genericLoader', ["$timeout", "$compile", "$rootScope", function($timeout, $compile, $rootScope) {
  var open = false;
  var scope = $rootScope.$new(true);
  return {
    timeout: 20000,
    hide: function() {
      if (open) {
        open = false;
        var loader = angular.element(document.querySelectorAll("[loader]"));
        loader.remove();
      }
    },
    show: function() {
      if (!open) {
        open = true;
        var body = angular.element(document.querySelectorAll("body"));
        var loader = '<div loader style="width: 100%;height: 100%;position: fixed;z-index: 9999;top: 0;border: 0;left: 0;right: 0;background-color:#000;opacity:0.5"><md-progress-circular style="position: relative;height: 100%;top: 40%;margin: 0 auto;" md-mode="indeterminate" md-diameter="150"></md-progress-circular></div>';
        body.append($compile(loader)(scope));
      }
    }
  };
}]);

;
___module_mybsit_core.directive('blockLoading', ["templateUrl", function(templateUrl) {
  return {
    restrict: 'E',
    scope: {
      resolved: '=',
      text: '='
    },
    templateUrl: templateUrl('loading'),
    replace: true,
    transclude: true,
    link: function(scope, element, attrs) {}
  };
}]);

;
___module_mybsit_core.directive('contentLoading', ["$rootScope", function($rootScope) {
  return {
    restrict: 'A',
    scope: false,
    link: function(scope, element, attrs) {
      element.addClass('appear ng-hide');
      scope.$watch(attrs.contentLoading, function(v) {
        if (v) {
          $rootScope.$emit('loading:hide');
          element.removeClass('ng-hide');
        } else {
          $rootScope.$emit('loading:show');
          element.addClass('ng-hide');
        }
      });
    }
  };
}]);

;
___module_mybsit_core.factory('$loader', ["$genericLoader", "$timeout", function($genericLoader, $timeout) {
  var loadingPromise = null;
  var isShown;
  return {
    timeout: 30000,
    hide: function() {
      isShown = false;
      $genericLoader.hide();
    },
    show: function(options, callback) {
      if (loadingPromise)
        $timeout.cancel(loadingPromise);
      $genericLoader.show();
      isShown = true;
      loadingPromise = $timeout(function() {
        if (isShown)
          $genericLoader.hide();
      }, options && options.timeout ? options.timeout : this.timeout);
    }
  };
}]);

;
___module_mybsit_core.service('popup', ["$mdDialog", "$rootScope", "uuid", "gettextCatalog", function($mdDialog, $rootScope, uuid, gettextCatalog) {
  var scope = $rootScope.$new(true);
  var current = null;
  var queue = [];
  scope.opened = false;
  scope.$watch('opened', function(isOpen) {
    if (!isOpen && queue.length) {
      var next = queue.shift();
      scope.$emit('next', next);
    }
  });
  this.confirm = function(title, content, cancelText, okText) {
    var show = function(title, content, cancelText, okText, resolve, reject) {
      scope.opened = true;
      $mdDialog.show($mdDialog.confirm().title(title).htmlContent(content).ok(okText || gettextCatalog.getString('Accept')).cancel(cancelText || gettextCatalog.getString('Cancel'))).then(function(res) {
        scope.opened = false;
        resolve(res);
      }).catch(function(res) {
        scope.opened = false;
        reject(res);
      });
    };
    return new Promise(function(resolve, reject) {
      if (scope.opened) {
        var id = uuid();
        queue.push(id);
        scope.$on('next', function(e, next) {
          if (next === id) {
            show(title, content, cancelText, okText, resolve, reject);
          }
        });
      } else {
        show(title, content, cancelText, okText, resolve, reject);
      }
    });
  };
  this.show = function(title, content) {
    var show = function(title, content, resolve, reject) {
      scope.opened = true;
      $mdDialog.show({
        title: title,
        template: '<md-dialog><md-dialog-content>' + content + '</md-dialog-content></md-dialog>'
      }).then(function(res) {
        scope.opened = false;
        resolve(res);
      }).catch(function(res) {
        scope.opened = false;
        reject(res);
      });
    };
    return new Promise(function(resolve, reject) {
      if (scope.opened) {
        var id = uuid();
        queue.push(id);
        scope.$on('next', function(e, next) {
          if (next === id) {
            show(title, content, resolve, reject);
          }
        });
      } else {
        show(title, content, resolve, reject);
      }
    });
  };
  this.raw = function(options) {
    var show = function(options, resolve, reject) {
      scope.opened = true;
      if (options.scope) {
        options.scope.cancel = $mdDialog.cancel;
        options.scope.hide = $mdDialog.hide;
      }
      $mdDialog.show(options).then(function(res) {
        scope.opened = false;
        resolve(res);
      }).catch(function(res) {
        scope.opened = false;
        reject(res);
      });
    };
    return new Promise(function(resolve, reject) {
      if (scope.opened) {
        var id = uuid();
        queue.push(id);
        scope.$on('next', function(e, next) {
          if (next === id) {
            show(options, resolve, reject);
          }
        });
      } else {
        show(options, resolve, reject);
      }
    });
  };
  this.alert = function(title, content, okText, evt) {
    var show = function(title, content, okText, evt, resolve, reject) {
      scope.opened = true;
      $mdDialog.show($mdDialog.alert().title(title).htmlContent(content).targetEvent(evt).ok(okText || 'Close')).then(function(res) {
        scope.opened = false;
        resolve(res);
      }).catch(function(res) {
        scope.opened = false;
        reject(res);
      });
    };
    return new Promise(function(resolve, reject) {
      if (scope.opened) {
        var id = uuid();
        queue.push(id);
        scope.$on('next', function(e, next) {
          if (next === id) {
            show(title, content, okText, evt, resolve, reject);
          }
        });
      } else {
        show(title, content, okText, evt, resolve, reject);
      }
    });
  };
  this.prompt = function(arg) {
    console.log("Shouldn't be used");
  };
}]);

;
___module_mybsit_core.factory('$s3Uploader', ["$q", function($q) {
  return function(url, file, config) {
    return $q(function(resolve, reject) {
      var xhr = new XMLHttpRequest();
      xhr.file = file;
      if (xhr.upload) {
        xhr.upload.onprogress = function(e) {
          var done = e.loaded || e.position;
          var total = e.total || e.totalSize;
          var percent = Math.floor(done / total * 1000) / 10;
          if (config && config.onProgress)
            config.onProgress(percent);
        };
      }
      xhr.onreadystatechange = function() {
        if (this.readyState === 4) {
          if (this.status === 200)
            resolve(this);
          else
            reject();
        }
      };
      xhr.open(config.requestType, url, true);
      if (config && config.data) {
        for (var k in config.data) {
          xhr.setRequestHeader(k, config.data[k]);
        }
      }
      xhr.send(file);
    });
  };
}]);
___module_mybsit_core.factory('$s3Upload', ["$q", "$http", "$s3Uploader", "$config", function($q, $http, $s3Uploader, $config) {
  return function(key, file, config, options) {
    return $q(function(resolve, reject) {
      var url = options && options.url ? options.url : $config.s3SignUrl;
      $http.get(url + '?key=' + key + '&type=image/jpeg').success(function(signature) {
        config.data = signature.headers;
        $s3Uploader(signature.putUrl, file, config).then(function(response) {
          return resolve(response);
        }).catch(function(err) {
          return reject(err);
        });
      }).error(reject);
    });
  };
}]);
___module_mybsit_core.factory('$s3UploadFile', ["$q", "$http", "$s3Uploader", "$config", function($q, $http, $s3Uploader, $config) {
  return function(key, file, config, options) {
    return $q(function(resolve, reject) {
      var url = options && options.url ? options.url : $config.s3SignUrl;
      $http.get(url + '?key=' + key).success(function(signature) {
        config.data = signature.headers;
        $s3Uploader(signature.putUrl, file, config).then(function(response) {
          return resolve(response);
        }).catch(function(err) {
          return reject(err);
        });
      }).error(reject);
    });
  };
}]);

;
___module_mybsit_core.run(["$http", function($http) {
  $http.defaults.useXDomain = true;
}]).config(["$httpProvider", function($httpProvider) {
  $httpProvider.interceptors.push('SessionHeaderInterceptor');
}]).factory('SessionHeaderInterceptor', ["Session", "$config", function(Session, $config) {
  return {request: function(config) {
      if (config.url && config.url.startsWith($config.apiUrl)) {
        if (Session.getDeviceId())
          config.headers['x-session-device'] = Session.getDeviceId();
        if (Session.getToken())
          config.headers['x-session-token'] = Session.getToken();
        if ($config.version)
          config.headers['x-session-version'] = (window.appstrap && window.appstrap.pack && window.appstrap.pack.version) ? window.appstrap.pack.version : $config.version;
      }
      return config;
    }};
}]).factory('SelfLogin', ["$q", "$timeout", function($q, $timeout) {
  return {
    id: 'self',
    login: function(user) {
      var defered = $q.defer();
      $timeout(function() {
        defered.resolve(user);
      }, 100);
      return defered.promise;
    },
    logout: function() {
      return {then: function(x) {
          x();
        }};
    },
    friends: function() {}
  };
}]).factory('PhoneNumberLogin', ["$q", "$timeout", function($q, $timeout) {
  return {
    id: 'phoneNumber',
    login: function(user) {
      var defered = $q.defer();
      $timeout(function() {
        defered.resolve(user);
      }, 100);
      return defered.promise;
    },
    logout: function() {
      return {then: function(x) {
          x();
        }};
    },
    friends: function() {}
  };
}]).factory('ProviderFactory', ["$injector", "SelfLogin", "PhoneNumberLogin", function($injector, SelfLogin, PhoneNumberLogin) {
  return function(reference) {
    if (reference == 'facebook') {
      return $injector.get('FacebookLogin');
    } else if (reference == 'google') {
      return $injector.get('GoogleLogin');
    } else if (reference == 'self') {
      return SelfLogin;
    } else if (reference == 'phoneNumber') {
      return PhoneNumberLogin;
    } else {
      return null;
    }
  };
}]).factory('Session', ["$config", "$store", "$injector", "uuid", function($config, $store, $injector, uuid) {
  var $sessionStore = $config.useSessionStore ? $injector.get('$sessionStore') : null;
  window.sessionStore = window.sessionStore || {};
  var data = {
    user: ($config.useSessionStore && window.sessionStore && window.sessionStore.identifiedUser) ? window.sessionStore.identifiedUser : $store.get('user') || {},
    deviceId: window.device ? window.device.uuid : (function() {
      var id = window.sessionStore.deviceId || $store.get('deviceId') || uuid();
      $store.set('deviceId', id);
      if ($sessionStore)
        $sessionStore.set('deviceId', id);
      return id;
    })(),
    token: window.sessionStore.token || $store.get('token')
  };
  return {
    getToken: function() {
      return data.token;
    },
    getDeviceId: function() {
      return data.deviceId;
    },
    isMe: function(userOrUserId) {
      var v = userOrUserId ? (userOrUserId._id || userOrUserId) : null;
      return data.user ? data.user._id === v : false;
    },
    getUser: function() {
      return data.user;
    },
    setUser: function(u) {
      if (!data.user)
        data.user = {};
      angular.extend(data.user, u);
      if ($sessionStore)
        $sessionStore.set('user', u);
      $store.set('user', u);
      if (u.token) {
        data.token = u.token;
        $store.set('token', u.token);
        if ($sessionStore)
          $sessionStore.set('token', u.token);
      }
    },
    clear: function() {
      $store.set('user', null);
      $store.set('token', null);
      if ($sessionStore)
        $sessionStore.set('user', null);
      if ($sessionStore)
        $sessionStore.set('token', null);
      data.user = null;
      data.token = null;
    }
  };
}]).factory('User', ["$resource", "$config", function($resource, $config) {
  return $resource($config.apiUrl + '/users/:id', {id: '@_id'}, {
    phoneNumber: {
      method: 'POST',
      params: {verify: 'phoneNumber'}
    },
    email: {
      method: 'POST',
      params: {verify: 'email'}
    }
  });
}]).factory('SessionService', ["Session", "$rootScope", "$loader", "$config", "$store", "$http", "$q", "User", "$timeout", "ProviderFactory", function(Session, $rootScope, $loader, $config, $store, $http, $q, User, $timeout, ProviderFactory) {
  var scope = $rootScope.$new(true);
  return {
    isLoggedIn: function() {
      return Session.getUser() && Session.getUser()._id;
    },
    getSession: function() {
      return Session;
    },
    on: function(action, handler) {
      scope.$on(action, handler);
    },
    login: function(user) {
      var defered = $q.defer();
      $loader.show();
      ProviderFactory(user.reference.name).login(user).then(function(data) {
        $http.post($config.apiUrl + '/login', data).success(function(u) {
          if (!user.preventLogin) {
            Session.setUser(u);
            scope.$emit('login', u);
            scope.$emit('changed');
          }
          $loader.hide();
          defered.resolve(u);
        }).error(function(err) {
          defered.reject(err);
          $loader.hide();
        });
      }, function(err) {
        $loader.hide();
        defered.reject(err);
      });
      return defered.promise;
    },
    updateUser: function(user) {
      var defered = $q.defer();
      if (Session.getUser()) {
        new User(user || Session.getUser()).$save(function(user) {
          Session.setUser(user);
          defered.resolve(user);
          scope.$emit('update', user);
          scope.$emit('changed');
        }, function() {
          defered.reject();
        });
      } else {
        defered.reject();
      }
      return defered.promise;
    },
    logout: function(callback) {
      var defered = $q.defer();
      scope.$emit('logout', Session.getUser());
      scope.$emit('changed');
      $timeout(function() {
        ProviderFactory(Session.getUser().reference.name).logout().then(function() {
          Session.clear();
          defered.resolve();
        });
      }, 100);
      return defered.promise;
    },
    refresh: function(callback) {
      return User.get({id: Session.getUser()._id}, function(u) {
        Session.setUser(u);
        if (callback)
          callback(u);
      });
    }
  };
}]);

;
___module_mybsit_core.factory('User', ["$resource", "$config", function($resource, $config) {
  return $resource($config.apiUrl + '/users/:id', {id: '@_id'}, {
    phoneNumber: {
      method: 'POST',
      params: {verify: 'phoneNumber'}
    },
    email: {
      method: 'POST',
      params: {verify: 'email'}
    }
  });
}]);

;
___module_mybsit_core.factory('uuid', function() {
  return function() {
    var time = new Date().getTime(),
        sixteen = 16;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(match) {
      var remainder = (time + sixteen * Math.random()) % sixteen | 0;
      time = Math.floor(time / sixteen);
      return (match == "x" ? remainder : remainder & 7 | 8).toString(sixteen);
    });
  };
});
